<template>
  <div class="page">
    <div class="page_title">设置中心</div>
    <div class="set_list">
      <div
        v-for="(item, index) in setData"
        :key="index"
        class="set_border"
        @click="changeData(item.linkUrl, item.developUrl)"
        @mouseleave="changImg(index)"
        @mouseenter="changImg(index)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="explan">{{ item.explan }}</div>
        <img :src="item.iconPathOff" alt="" v-if="item.urlType" />
        <img :src="item.iconPathOn" alt="" v-else />
      </div>
    </div>
    <QuickLink :linkList="linkData"></QuickLink>
  </div>
</template>

<script>
import QuickLink from "../components/QuickLink/index.vue";
export default {
  components: {
    QuickLink,
  },
  data() {
    return {
      setData: [
        {
          title: "资产组合",
          explan: "Rspace 房源管理",
          linkUrl: "/rspace/#/house",
          developUrl: "/rspace/#/house",
          iconPathOff: require("@/assets/images/bms/settingIconBl_1.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_1.png"),
          urlType: true,
        },
        {
          title: "组织职员权限",
          explan: "BMS 权限管理",
          iconPathOff: require("@/assets/images/bms/settingIconBl_2.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_2.png"),
          urlType: true,
          linkUrl: "/#/tenant/permission/grant",
        },
        {
          title: "客户招商设置",
          explan: "CRM 客户管理设置",
          iconPathOff: require("@/assets/images/bms/settingIconBl_3.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_3.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/manage/customer/biz-param"),
        },
        {
          title: "合同参数",
          explan: "Rspace 合同模板配置",
          iconPathOff: require("@/assets/images/bms/settingIconBl_4.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_4.png"),
          urlType: true,
          linkUrl:
            "/rspace/#/configuration/contract-template-config/template-list",
          developUrl:
            "/rspace/#/configuration/contract-template-config/template-list",
        },
        {
          title: "合同到期预警",
          explan: "Rspace 合同预警配置",
          iconPathOff: require("@/assets/images/bms/settingIconBl_5.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_5.png"),
          urlType: true,
          linkUrl: "/rspace/#/configuration/make-collection-config",
          developUrl: "/rspace/#/configuration/make-collection-config",
        },
        {
          title: "工单巡检预警设置",
          explan: "Rwork 流程配置",
          iconPathOff: require("@/assets/images/bms/settingIconBl_6.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_6.png"),
          urlType: true,
          linkUrl: "/#/tenant/order/orderConfig/list",
        },
        {
          title: "智能设备预警设置",
          explan: "Rlink 规则",
          iconPathOff: require("@/assets/images/bms/settingIconBl_7.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_7.png"),
          urlType: true,
          linkUrl: "/#/iot/rule/list",
        },
        {
          title: "仪表设置",
          explan: "Rspace 设备配置",
          iconPathOff: require("@/assets/images/bms/settingIconBl_8.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_8.png"),
          urlType: true,
          linkUrl: "/rspace/#/configuration/device-config",
          developUrl: "/rspace/#/configuration/device-config",
        },
        {
          title: "财务设置",
          explan: "Rspace 财务管理",
          iconPathOff: require("@/assets/images/bms/settingIconBl_9.png"),
          iconPathOn: require("@/assets/images/bms/settingIconWh_9.png"),
          urlType: true,
          linkUrl:
            "/rspace/#/configuration/financial-management/account-set-management",
          developUrl:
            "/rspace/#/configuration/financial-management/account-set-management",
        },
        // {
        //   title: "资产管理流程设置",
        //   explan: "BMS 资产管理",
        //   iconPathOff: require("@/assets/images/bms/settingIconBl_10.png"),
        //   iconPathOn: require("@/assets/images/bms/settingIconWh_10.png"),
        //   urlType: true,
        //   linkUrl: "",
        // },
      ],
      linkData: [
        //快捷链接
        {
          title: "Rspace 配置中心",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: "/rspace/#/configuration",
          developUrl: "/rspace/#/configuration",
        },
        {
          title: "Rwork 基础配置",
          iconPathOff: require("@/assets/images/bms/link_dispose.png"),
          iconPathOn: require("@/assets/images/bms/link_dispose_wh.png"),
          urlType: true,
          linkUrl: "/#/tenant/organization/manager",
        },
        {
          title: "CRM 企业管理后台",
          iconPathOff: require("@/assets/images/bms/link_back.png"),
          iconPathOn: require("@/assets/images/bms/link_back_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/manage/systemconfig"),
        },
      ],
      currentEnv: "",
    };
  },
  created() {
    if (process.env.VUE_APP_ENV == "DEV" || process.env.VUE_APP_ENV == "SIT") {
      this.currentEnv = "https://sit.rlinkiot.com";
    } else if (process.env.VUE_APP_ENV == "UAT") {
      this.currentEnv = "https://uat.rlinkiot.com";
    } else if (process.env.VUE_APP_ENV == "PROD") {
      this.currentEnv = "https://prod.rlinkiot.com";
    }
  },
  methods: {
    crmLinkRender(path) {
      const pathMap = {
        DEV: "https://test-crm.rlinkiot.com/#",
        SIT: "https://test-crm.rlinkiot.com/#",
        UAT: "https://test-crm.rlinkiot.com/#",
        PROD: "https://crm.rlinkiot.com/#",
      };
      return `${pathMap[process.env.VUE_APP_ENV]}${path}?auth-token=${
        this.$store.getters.baseAuthToken
      }`;
    },
    changImg(index) {
      this.setData[index].urlType = !this.setData[index].urlType;
    },
    changeData(linkUrl, developUrl) {
      console.log("this.currentEnv", this.currentEnv);
      //判断是否有developUrl，并且是prod环境
      if (developUrl && process.env.VUE_APP_ENV == "PROD") {
        window.open(this.currentEnv + developUrl, "_blank");
      } else if (linkUrl) {
        if (linkUrl.includes("http")) {
          window.open(linkUrl, "_blank");
        } else {
          window.open(this.currentEnv + linkUrl, "_blank");
        }
      } else {
        this.$message({
          message: "暂无地址",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  padding: 32px 16px 32px 40px;
  height: calc(100vh - 60px);
  overflow: auto;
  .page_title {
    font-size: 20px;
    font-family: Noto Sans SC-Bold, Noto Sans SC;
    font-weight: bold;
    color: #000000;
  }
  .set_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .set_border {
      position: relative;
      width: 305px;
      height: 130px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ebeef5;
      margin: 16px 16px 0px 0px;
      .title {
        margin: 16px 0px 0px 56px;
        height: 24px;
        font-size: 16px;
        font-family: Noto Sans SC-Medium, Noto Sans SC;
        font-weight: 500;
        color: #2f3941;
        line-height: 24px;
      }
      .explan {
        margin: 54px 0px 16px 24px;
        height: 20px;
        font-size: 14px;
        font-family: Noto Sans SC-Regular, Noto Sans SC;
        font-weight: 400;
        color: #9099a9;
        line-height: 20px;
      }

      img {
        position: absolute;
        top: 16px;
        left: 24px;
        width: 24px;
        height: 24px;
        &:hover {
          fill: #000000;
        }
      }
    }

    .set_border:hover,
    .set_border:active {
      cursor: pointer;
      background: #1a4cec;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #1a4cec;
      .title {
        color: #ffffff;
      }
      .explan {
        color: #ffffff;
      }
    }
  }
}
</style>