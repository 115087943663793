var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page_title" }, [_vm._v("设置中心")]),
      _c(
        "div",
        { staticClass: "set_list" },
        _vm._l(_vm.setData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "set_border",
              on: {
                click: function ($event) {
                  return _vm.changeData(item.linkUrl, item.developUrl)
                },
                mouseleave: function ($event) {
                  return _vm.changImg(index)
                },
                mouseenter: function ($event) {
                  return _vm.changImg(index)
                },
              },
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
              _c("div", { staticClass: "explan" }, [
                _vm._v(_vm._s(item.explan)),
              ]),
              item.urlType
                ? _c("img", { attrs: { src: item.iconPathOff, alt: "" } })
                : _c("img", { attrs: { src: item.iconPathOn, alt: "" } }),
            ]
          )
        }),
        0
      ),
      _c("QuickLink", { attrs: { linkList: _vm.linkData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }